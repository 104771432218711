<template>
  <div v-editable="blok" class="bg-white relative">
    <div
      class="mx-auto max-w-7xl py-6 px-4 lg:py-24 sm:px-6 lg:px-8 relative z-30"
    >
      <div class="mx-auto max-w-3xl">
        <h2
          v-if="blok.title"
          class="text-center heading-2"
        >
          {{ blok.title }}
        </h2>
        <h3 class="text-center pt-5 max-w-2xl text-lg text-grey-dark">
          JustHire offers the easiest online skip hire in the UK. Use our handy guides to ensure your skip hire goes as smoothly as possible.
        </h3>
      </div>

      <div class="mx-auto max-w-5xl mt-6 lg:mt-16">
        <Faq
          v-for="faq in blok.faqs"
          :key="faq.question"
          :faq="faq"
        >
        </Faq>
      </div>

      <div class="text-center mt-10 lg:mt-20">
        <h4 class="subtitle-1">{{ blok.footerTitle }}</h4>
        <div class="mt-2 lg:mt-6 text-grey-dark font-normal text-base" v-html="resolvedRichText"></div>
        <Button class="mt-6" as="nuxt-link" to="/contact-us" size="lg">Contact us</Button>
      </div>
    </div>

    <Gradients :gradients="blok.gradients"/>
  </div>
</template>

<script setup>
const props = defineProps({ blok: Object, filter: String });

function structuredData() {
  let data = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [],
  };

  props.blok.faqs.forEach((faq) => {
    data.mainEntity.push({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.answer,
      },
    });
  });

  return data;
}

// Resolve the rich text (service.list) using the Storyblok renderer
const resolvedRichText = computed(() =>
  renderRichText(props.blok.footerSubtitle, {
    resolver: (component, blok) => {
      return `<component :blok='${JSON.stringify(
        blok
      )}' is="${component}"></component>`;
    },
  })
);

useHead({
  script: [
    {
      innerHTML: JSON.stringify(structuredData()),
      type: "application/ld+json",
    },
  ],
});
</script>
